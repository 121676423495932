import { useEffect, useState, FC } from "react";

import { FolderOpenIcon, Square2StackIcon, TrashIcon, LinkIcon, PencilIcon } from "@heroicons/react/24/outline";
import { DestinationInstancesInsertInput } from "@hightouch/core/server/graphql/types";
import {
  Box,
  Button,
  Column,
  Row,
  SectionHeading,
  EditableHeading,
  EditableDescription,
  Link,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
  Pill,
  Text,
  Avatar,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import get from "lodash/get";
import pluralize from "pluralize";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { AudienceTraits } from "src/components/audiences/audience-traits";
import { DetailBar } from "src/components/detail-bar";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { useLabels } from "src/components/labels/use-labels";
import { DetailPage } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Syncs } from "src/components/models/syncs";
import { Performance } from "src/components/performance";
import { Permission } from "src/components/permission";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { Splits } from "src/components/splits";
import { Warning } from "src/components/warning";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  DraftOperation,
  ResourcePermissionGrant,
  useAudienceQuery,
  useCreateSyncsMutation,
  useDeleteAudienceMutation,
  useSubmitDraftSyncMutation,
  useSyncTemplatesForParentModelQuery,
  useUpdateAudienceMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { isPresent } from "src/types/utils";
import { SplitTestDefinition } from "src/types/visual";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { EMPTY_AUDIENCE_DEFINITION, QueryState, useQueryState, useUpdateQuery } from "src/utils/models";
import { generateSlug } from "src/utils/slug";
import { formatDate } from "src/utils/time";

import { audienceActivityMappers } from "./setup/audience-activity";

enum Tab {
  QUERY = "/query",
  SYNCS = "/syncs",
  PERFORMANCE = "/performance",
  TRAITS = "/traits",
  SPLITS = "/splits",
  ACTIVITY = "/activity",
}

export const Audience: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { user, workspace } = useUser();
  const [deleteModal, setDeleteModal] = useState(false);
  const [addSyncModal, setAddSyncModal] = useState(false);
  const [editLabelsModal, setEditLabelsModal] = useState(false);
  const { selectedRows: syncTemplateIdsToAdd, onRowSelect: onSyncTemplateSelected } = useRowSelect();
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  const { appAudienceSplits: splitsEnabled, appPriorityLists, appDisableSyncCreationForAudiences, appEnableGoals } = useFlags();

  const {
    queryState,
    initQueryState,
    setVisualQueryFilter,
    canRedoVisualQueryFilterChange,
    canUndoVisualQueryFilterChange,
    redoVisualQueryFilterChange,
    undoVisualQueryFilterChange,
    resetVisualQueryFilter,
  } = useQueryState();
  const updateQuery = useUpdateQuery();

  const pathToTab = {
    [`/audiences/${id}/query`]: Tab.QUERY,
    [`/audiences/${id}/splits`]: Tab.SPLITS,
    [`/audiences/${id}/syncs`]: Tab.SYNCS,
    [`/audiences/${id}/performance`]: Tab.PERFORMANCE,
    [`/audiences/${id}/traits`]: Tab.TRAITS,
    [`/audiences/${id}/activity`]: Tab.ACTIVITY,
  };

  const tab = get(pathToTab, pathname, Tab.QUERY);

  const {
    data: audience,
    isLoading: audienceLoading,
    refetch,
  } = useAudienceQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id), select: (data) => data.segments_by_pk },
  );
  const parentModel = audience?.parent;

  const visualQueryFilter = audience?.visual_query_filter;
  const traits = visualQueryFilter?.additionalColumns;

  const { labels } = useLabels();
  const [name, setName] = useState(audience?.name ?? "");
  const { mutateAsync: updateAudience, isLoading: updating } = useUpdateAudienceMutation();
  const { mutateAsync: deleteAudience } = useDeleteAudienceMutation();
  const { mutateAsync: addSyncs, isLoading: addingSyncs } = useCreateSyncsMutation();
  const { mutateAsync: submitDraftSync, isLoading: submittingDraftSync } = useSubmitDraftSyncMutation();
  const { data: syncTemplatesData } = useSyncTemplatesForParentModelQuery(
    { parentModelId: parentModel?.id },
    { enabled: Boolean(parentModel) },
  );

  const { hasPermission: hasViewSchemaPermissions } = useHasPermission([
    { resource: "audience_schema", grants: [ResourcePermissionGrant.Read], resource_id: parentModel?.id },
  ]);
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Update], resource_id: id },
  ]);
  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Delete], resource_id: id },
  ]);
  const { hasPermission: userCanCreate } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Create] },
  ]);

  const folder = useFolder({ folderId: audience?.folder?.id ?? null, folderType: "audiences", viewType: "models" });

  useEffect(() => {
    setName(audience?.name ?? "");
  }, [audience?.name]);

  const [description, setDescription] = useState(audience?.description || "");

  useEffect(() => {
    setDescription(audience?.description ?? "");
  }, [audience?.description]);

  useEffect(() => {
    initQueryState(audience);
  }, [audience]);

  useEffect(() => {
    refetch();
  }, [tab]);

  if (!id || audienceLoading) {
    return <PageSpinner />;
  }

  if (!audience) {
    return <Warning subtitle="It may have been deleted" title="Audience not found" />;
  }

  const onAddSync = () => {
    if (hasSyncTemplates) {
      setAddSyncModal(true);
    } else {
      navigate(`/syncs/new?model=${id}`);
    }
  };

  const onAddSyncTemplates = async () => {
    const approvalsRequired = workspace?.approvals_required;

    const syncObjects: DestinationInstancesInsertInput[] = syncTemplateIdsToAdd
      .map((id) => syncTemplates?.find((template) => template.id === id))
      .filter(Boolean)
      .map((template: any) => ({
        segment_id: id,
        sync_template_id: template.id,
        slug: generateSlug(template.name),
        draft: approvalsRequired,
      }));

    const syncs = await addSyncs(
      {
        objects: syncObjects,
      },
      {
        onSuccess: () => {
          toast({
            id: "add-sync-to-audience",
            title: `${pluralize("sync", syncTemplateIdsToAdd.length, true)} added`,
            variant: "success",
          });
        },

        onError: (error) => {
          toast({
            id: "add-sync-to-audience",
            title: `${pluralize("sync", syncTemplateIdsToAdd.length, true)} could not be added. Please try again.`,
            variant: "error",
          });

          Sentry.captureException(error);
        },
      },
    );

    if (approvalsRequired) {
      const syncIds: string[] = (syncs.insert_destination_instances?.returning ?? []).map(({ id }) => id.toString());

      // Save newly created syncs as new drafts without approvers.
      // Approvers will be requested later from the individual sync pages.
      await Promise.all(
        syncIds.map(async (syncId: string) => {
          return submitDraftSync(
            {
              resourceId: syncId,
              approverIds: [],
              operation: DraftOperation.Create,
              draft: {
                _set: {
                  draft: false,
                },
              },
            },
            {
              onError: (error) => {
                Sentry.captureException(error);
              },
            },
          );
        }),
      );
    }

    setAddSyncModal(false);
  };

  const updatedByUsername = audience.updated_by_user?.name || audience.created_by_user?.name;
  const membership = audience?.priority_list_memberships?.[0];
  const syncs = audience?.syncs;
  const source = audience?.connection;
  const splits = audience?.splits;
  const labelKeys = Object.keys(audience?.labels || {});
  const goals = audience?.audience_goals?.map(({ goal }) => goal);

  const usedSyncTemplateIds = syncs?.map(({ sync_template_id }) => sync_template_id);
  const syncTemplates = syncTemplatesData?.sync_templates;
  const usedSyncTemplates = syncTemplates?.filter(({ id }) => usedSyncTemplateIds?.includes(id));
  const availableSyncTemplates = syncTemplates?.filter(({ id }) => !usedSyncTemplateIds?.includes(id));

  const hasSyncTemplates = Array.isArray(syncTemplates) && syncTemplates.length > 0;
  const cannotCreateNewSyncs = appDisableSyncCreationForAudiences && !hasSyncTemplates;

  const save = async (data, queryStateOverride: Partial<QueryState> = {}, showToast = true) => {
    await updateQuery({
      model: audience,
      queryState: {
        ...queryState,
        ...queryStateOverride,
      },
      columns: data?.columns,
    });

    onUpdate(showToast);
  };

  const saveSplitDefinition = async (splitTestDefinition: SplitTestDefinition | undefined, showToast = true) => {
    const newVisualQueryFilter = { ...(queryState.visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION), splitTestDefinition };
    setVisualQueryFilter(newVisualQueryFilter);

    return await save(undefined, { visualQueryFilter: newVisualQueryFilter }, showToast);
  };

  const onUpdate = (showToast = true) => {
    analytics.track("Model Updated", {
      model_id: id,
      model_type: QueryType.Visual,
      model_name: audience?.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    if (showToast) {
      toast({
        id: "update-audience",
        title: "Audience was updated",
        variant: "success",
      });
    }
  };

  const saveName = async () => {
    await updateAudience({
      id,
      input: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const saveLabels = async (labels: Record<string, string | number>) => {
    await updateAudience({
      id,
      input: {
        tags: labels,
      },
    });

    onUpdate();
  };

  const saveDescription = async (description: string) => {
    if (!id) {
      return;
    }

    await updateAudience({
      id,
      input: {
        description,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const onDelete = async () => {
    try {
      await deleteAudience({ id });

      analytics.track("Model Deleted", {
        model_id: id,
        model_type: QueryType.Visual,
        model_name: audience?.name,
        source_id: source?.id,
        source_type: source?.type,
      });

      navigate("/audiences");
    } catch (error) {
      toast({
        id: "delete-audience",
        title: "Failed to delete this audience",
        variant: "error",
      });

      Sentry.captureException(error);

      throw error;
    }
  };

  const tabs = [
    { value: "Query", title: `${audience.name} - Query - Audiences`, path: Tab.QUERY },
    splitsEnabled && {
      render: () => (
        <Box>
          Splits
          {splits?.length > 0 && <Pill ml={2}>{splits.length}</Pill>}
        </Box>
      ),
      title: `${audience.name} - Splits - Audiences`,
      path: Tab.SPLITS,
    },
    {
      render: () => (
        <Box>
          Syncs
          {syncs?.length > 0 && <Pill ml={2}>{syncs.length}</Pill>}
        </Box>
      ),
      title: `${audience.name} - Syncs - Audiences`,
      path: Tab.SYNCS,
    },
    appEnableGoals && { value: "Performance", title: `${audience.name} Performance Page - Audiences`, path: Tab.PERFORMANCE },
    {
      render: () => (
        <Box>
          Traits
          {traits?.length > 0 && <Pill ml={2}>{traits.length}</Pill>}
        </Box>
      ),
      title: `${audience.name} - Traits - Audiences`,
      path: Tab.TRAITS,
    },
    { value: "Activity", title: `${audience.name} - Activity - Audiences`, path: Tab.ACTIVITY },
  ].filter(isPresent);

  const handleTabNavigate = (tabIndex: number) => {
    navigate(`/audiences/${id}/${tabs[tabIndex].path}`);
  };

  const crumbs: Crumb[] = [{ label: "Audiences", link: "/audiences" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: "/audiences?folder=" + folder.id,
      });
    });
  }

  crumbs.push({
    label: audience?.name ?? "",
  });

  return (
    <>
      <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
        <DetailPage
          bg={location.pathname.endsWith("/query") ? "base.lightBackground" : "white"}
          contentFullWidth={tab === Tab.QUERY}
          crumbs={crumbs}
          header={
            <Column mb={6} width="100%">
              <Row sx={{ alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                <EditableHeading isDisabled={!userCanUpdate} size="lg" value={name} onChange={setName} onSubmit={saveName} />
                <Row gap={3} sx={{ alignItems: "center", ml: 8 }}>
                  {(userCanUpdate || userCanCreate || userCanDelete) && (
                    <Menu>
                      <MenuActionsButton variant="secondary" />
                      <MenuList>
                        {userCanUpdate && (
                          <MenuItem
                            icon={FolderOpenIcon}
                            onClick={() => {
                              setIsFolderModalOpen(true);
                            }}
                          >
                            Move to folder
                          </MenuItem>
                        )}
                        {userCanUpdate && (
                          <MenuItem
                            icon={PencilIcon}
                            onClick={() => {
                              setEditLabelsModal(true);
                            }}
                          >
                            Edit labels
                          </MenuItem>
                        )}
                        {userCanCreate && (
                          <MenuItem
                            icon={Square2StackIcon}
                            onClick={() => {
                              navigate(`/audiences/${id}/clone`);
                            }}
                          >
                            Clone
                          </MenuItem>
                        )}
                        {(userCanUpdate || userCanCreate) && userCanDelete && <MenuDivider />}
                        {userCanDelete && (
                          <MenuItem
                            icon={TrashIcon}
                            variant="danger"
                            onClick={() => {
                              setDeleteModal(true);
                            }}
                          >
                            Delete
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  )}

                  {!cannotCreateNewSyncs && (
                    <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          onAddSync();
                        }}
                      >
                        Add sync
                      </Button>
                    </Permission>
                  )}
                </Row>
              </Row>
              <Row>
                <EditableDescription
                  isDisabled={!userCanUpdate}
                  value={description}
                  onChange={setDescription}
                  onSubmit={saveDescription}
                />
              </Row>
              <Row sx={{ mt: 1 }}>
                <DetailBar>
                  <Row align="center" gap={2} flexShrink={0}>
                    <IntegrationIcon src={source?.definition?.icon} name={source?.definition?.name ?? ""} />
                    {hasViewSchemaPermissions ? (
                      <Link href={`/schema/parent-models/${parentModel?.id}`}>
                        <Text isTruncated fontWeight="medium" color="inherit">
                          {parentModel?.name}
                        </Text>
                      </Link>
                    ) : (
                      <Text isTruncated fontWeight="medium">
                        {parentModel?.name}
                      </Text>
                    )}
                  </Row>
                  <Row align="center" gap={2} flexShrink={0}>
                    <Text>Last updated:</Text>
                    <Row gap={1} align="center">
                      {formatDate((audience.updated_at || audience.created_at)!)}
                      {updatedByUsername && (
                        <>
                          <Text>by</Text>
                          <Avatar size="xs" name={updatedByUsername} />
                        </>
                      )}
                    </Row>
                  </Row>
                  {appPriorityLists && membership && (
                    <Row align="center" gap={2}>
                      <Text>#{membership.rank + 1} in</Text>
                      <Link href={`/priority-lists/${membership.priority_list.id}`}>
                        <Box
                          sx={{
                            color: "primary",
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            ":hover": { color: "secondary" },
                          }}
                        >
                          {membership.priority_list.name}
                          <LinkIcon width="16px" />
                        </Box>
                      </Link>
                    </Row>
                  )}
                  {labelKeys?.length > 0 && (
                    <Row>
                      <Labels labels={audience?.labels} />
                    </Row>
                  )}
                </DetailBar>
              </Row>
            </Column>
          }
          tab={tab}
          tabs={tabs}
          onTabClick={handleTabNavigate}
        >
          <Routes>
            <Route
              path="/query"
              element={
                <FormErrorProvider>
                  <AudienceExplore
                    audience={audience}
                    bodyOverflow="hidden"
                    canRedo={canRedoVisualQueryFilterChange}
                    canUndo={canUndoVisualQueryFilterChange}
                    parentModel={parentModel}
                    queryState={queryState}
                    source={source}
                    onRedo={redoVisualQueryFilterChange}
                    onReset={() => resetVisualQueryFilter(visualQueryFilter)}
                    onSave={save}
                    onUndo={undoVisualQueryFilterChange}
                    onVisualQueryFilterChange={setVisualQueryFilter}
                  />
                </FormErrorProvider>
              }
            />
            <Route
              path="/splits"
              element={
                audience && queryState.visualQueryFilter ? (
                  <Splits
                    audience={audience}
                    data={queryState.visualQueryFilter?.splitTestDefinition}
                    sourceType={source?.type}
                    onAddSync={onAddSync}
                    onSave={saveSplitDefinition}
                  />
                ) : null
              }
            />
            <Route path="/syncs" element={<Syncs isAudience syncs={syncs} onAdd={onAddSync} />} />
            {appEnableGoals && audience && (
              <Route path="/performance" element={<Performance audience={audience} metrics={goals} />} />
            )}
            <Route path="/traits" element={audience ? <AudienceTraits audience={audience} /> : null} />
            <Route
              path="/activity"
              element={
                id ? (
                  <ResourceActivityTimeline
                    primaryResource={{
                      mappers: audienceActivityMappers,
                      resource: "Audience",
                      resourceId: String(id),
                    }}
                  />
                ) : null
              }
            />
          </Routes>
        </DetailPage>
      </PermissionProvider>

      <Modal
        footer={
          <>
            <Button
              onClick={() => {
                setAddSyncModal(false);
              }}
            >
              Cancel
            </Button>

            {!appDisableSyncCreationForAudiences && (
              <Button isDisabled={syncTemplateIdsToAdd.length > 0} onClick={() => navigate(`/syncs/new?model=${id}`)}>
                Create new sync
              </Button>
            )}
            <Button
              isDisabled={syncTemplateIdsToAdd?.length === 0}
              isLoading={addingSyncs || submittingDraftSync}
              onClick={onAddSyncTemplates}
            >
              Add syncs
            </Button>
          </>
        }
        isOpen={addSyncModal}
        sx={{ width: "900px" }}
        title="Select sync templates"
        onClose={() => {
          setAddSyncModal(false);
        }}
      >
        <Column gap={6}>
          <Column>
            {availableSyncTemplates?.length === 0 ? (
              <Text fontWeight="medium">
                You have no available sync templates. <Link href="/schema/sync-templates/new">Create a new sync template</Link>{" "}
                to easily sync audiences to other destinations.
              </Text>
            ) : (
              <>
                <Text fontWeight="medium">
                  Select one or more sync templates below. Templates are pre-configured to work with this Audience's data
                  source.
                </Text>
                <Text fontWeight="medium">
                  If your destination isn't listed below, <Link href={`/syncs/new?model=${id}`}>create a new sync</Link>.
                </Text>
              </>
            )}
          </Column>
          {Array.isArray(availableSyncTemplates) && availableSyncTemplates.length > 0 && (
            <Column gap={3}>
              <SectionHeading>Available sync templates</SectionHeading>
              <Table
                data={availableSyncTemplates}
                columns={syncTemplateColumns}
                onSelect={onSyncTemplateSelected}
                selectedRows={syncTemplateIdsToAdd}
              />
            </Column>
          )}
          {Array.isArray(usedSyncTemplates) && usedSyncTemplates.length > 0 && (
            <Column gap={3}>
              <SectionHeading>Active sync templates</SectionHeading>
              <Table
                data={usedSyncTemplates}
                columns={syncTemplateColumns}
                onRowClick={() => navigate(`/schema/sync-templates/${id}`)}
              />
            </Column>
          )}
        </Column>
      </Modal>

      <DeleteConfirmationModal
        isOpen={deleteModal}
        label="audience"
        onClose={() => {
          setDeleteModal(false);
        }}
        onDelete={onDelete}
      />

      {audience && isFolderModalOpen && (
        <MoveFolder
          folder={audience.folder}
          folderType="audiences"
          modelIds={[audience.id]}
          viewType="models"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <EditLabels
        description="You can label audiences that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={editLabelsModal}
        labels={audience?.labels}
        loading={updating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setEditLabelsModal(false)}
        onSave={async (labels) => {
          await saveLabels(labels);
          setEditLabelsModal(false);
        }}
      />
    </>
  );
};

const syncTemplateColumns = [
  {
    name: "Name",
    key: "name",
    cell: (name) => (
      <Text isTruncated fontWeight="medium">
        {name}
      </Text>
    ),
  },
  {
    name: "Destination",
    cell: ({ destination }) => {
      return (
        <Row align="center" gap={2} overflow="hidden">
          <IntegrationIcon name={destination?.definition?.name} src={destination?.definition?.icon} />
          <Link href={`/schema/sync-templates/${destination.id}`}>
            <Text isTruncated fontWeight="medium">
              {destination?.name ?? destination?.definition?.name ?? "Private destination"}
            </Text>
          </Link>
        </Row>
      );
    },
  },
];
